import React from 'react';
import styled from 'styled-components';

import {FaFacebookSquare, FaPinterestP, FaInstagram, FaYoutube} from 'react-icons/fa'
const Footer2 = () => {
    return (
        <Foot2Wrap >
<footer className="page-footer font-small special-color-dark pt-4">



  <p className="footer-copyright text-center m-0 py-3">© 2018 Copyright:
    <a href="#"> 1990Minds.com</a>
  </p>

</footer>




        </Foot2Wrap>
    );
};

const Foot2Wrap = styled.div`

background-color:#111111;

ul{
    padding:40px 20px;
}

a{
    font-size:18px !important;
    color: #f5f5f5 !important;
    opacity:0.5;
    transition:0.2s;
    text-decoration:none !important;

    &:hover{

      opacity:0.9;
    }
  

}
.footer-copyright{

    background-color:#1E282D;
      color:white !important;
      opacity:0.5;
    
}
`



export default function Sociallinks() {
  return (
   
      
      <SocialWrap className="container">
      <h5 className="text-uppercase text-white">Social Media</h5>

<ul className=" mnstyle list-unstyled p-0 list-inline text-left">
  <li className="list-inline-item">
    <a href="https://www.facebook.com/designseriesofficial" target="new_tab" className="btn-floating btn-fb mx-1">
      <FaFacebookSquare/>
    </a>
  </li>

  <li className="list-inline-item">
    <a href="https://www.instagram.com/designseriesofficial/" target="new_tab" className="btn-floating btn-gplus mx-1">
<FaInstagram/>
    </a>
  </li>
  <li className="list-inline-item">
    <a href="https://in.pinterest.com/designseriesofficial/" target="new_tab" className="btn-floating btn-li mx-1">
<FaPinterestP/>
    </a>
  </li>

  <li className="list-inline-item">
    <a href="https://www.youtube.com/channel/UC_haKFLOrkCUmykIsO55OUQ?view_as=subscriber" target="new_tab" className="btn-floating btn-li mx-1">
<FaYoutube/>
    </a>
  </li>

</ul>

</SocialWrap>


  )
}

const SocialWrap = styled.div`

background-color:#111111;

ul{
    padding:12px 0px;
}

a{
    font-size:25px !important;
    color: #f5f5f5 !important;
    opacity:0.5;
    transition:0.2s;
    text-decoration:none !important;

    &:hover{

      opacity:0.9;
    }
  

}

@media(max-width:960px){

.mnstyle{

display:flex;
align-items:center;
justify-content:center;

}

}

`

export {Footer2, Sociallinks } ;