import React from 'react';
import styled from 'styled-components'
import logo from '../../../images/logo.png'
import { Link} from 'gatsby'
import {  Sociallinks  } from './footer2'

const footer = () => {
    return (
        <FootWrap className="py-sm-5 ">
          
<footer className="page-footer font-small blue pt-4">

  <div className="container text-center text-md-left">

    <div className="row">

    <div className="col-md-5 mb-md-0 mb-3">

        <h5 className="text-uppercase text-white">Company</h5>
<Link to="/">
        <img  src={logo} className="mt-3" alt="flogo" width="60%"/>
</Link>

      </div>

      <hr className="clearfix w-100 d-md-none pb-3"/>

      <div className="col-md-4 mb-md-0 mb-3">

        <h5 className="text-uppercase text-white">Quick Links</h5>

        <ul className="list-unstyled mt-3">
          <li>
            <Link activeStyle={{ color: "var(--mainBlue)" }} to="house">Residential</Link>
          </li>
          <li>
          <Link activeStyle={{ color: "var(--mainBlue)" }} to="office">Office</Link>
          </li>
          <li>
          <Link activeStyle={{ color: "var(--mainBlue)" }} to="kitchen">Kitchen</Link>
          </li>
          <li>
          <Link activeStyle={{ color: "var(--mainBlue)" }} to="/wardrobe/">Wardrobe</Link>
          </li>
        </ul>

      </div>

      <div className="col-md-3 mb-md-0 mb-3">

     <Sociallinks/>
      </div>

    </div>

  </div>

</footer>
            
        </FootWrap>
    );
};

const FootWrap = styled.div`

background-color:#111111;
a{
    color:grey ;
    text-decoration:none !important;
    transition:0.2s;
    &:hover{

      color:var(--mainBlue);
    }
}

`

export default footer;