import React, { Component } from 'react';
import NavbarHeader from './NavbarHeader';
import NavbarLinks from './NavbarLinks';
import styled from 'styled-components';
class Navbar extends Component {
    constructor(props) {
		super(props);

		this.state = { navbarOpen:false};

		this.handleScroll = this.handleScroll.bind(this);
	}

	handleScroll() {
		this.setState({scroll: window.scrollY, color:"white"});
	}
  
  componentDidMount() {

		this.setState({top: 200 });
		window.addEventListener('scroll', this.handleScroll);
	}
  
  componentDidUpdate() {
    const el = document.getElementById("navbar");

    if(this.state.scroll  > this.state.top ){

        el.style.position = "sticky";
        el.style.background  = " #111111";
        el.style.height = "70px";
        

    }else{

        el.style.position = "relative";
        el.style.background = "#111111";
        el.style.height = "70px";

    }
		
	}
  

    handleNavbar = () =>{

this.setState(()=>{

    return {navbarOpen:!this.state.navbarOpen}
})

    }
    render() {
        return (
            <NavWrapper id="navbar">
 
<NavbarHeader handleNavbar={this.handleNavbar}/>
<NavbarLinks color={this.state} navbarOpen = {this.state.navbarOpen}/>

            </NavWrapper>
        );
    }
}

const NavWrapper = styled.nav`
z-index:22;
  top: 0;
background-color:var(--mainBlue);



@media (min-width:768px){



    display:flex ;
    align-items:center;
    color:white;
    

}
`

export default Navbar;