import React, { Component } from 'react';
import { Link } from 'gatsby';
import logo from '../../../images/logo.png'
import styled from 'styled-components';
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

class NavbarLinks extends Component {

 

    render() {
        return (
            <LinkWrapper color={this.props.color} open={this.props.navbarOpen}>
             

<li>

    <Link to='/'  activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
Home
    </Link>
   
</li>

<li>

    <Link to='/office' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
Office
    </Link>
   
</li>

<li>

    <Link to='/house' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
Residential
    </Link>
   
</li>



<li id="logo">

    <Link to='/' activeStyle={{ color: "var(--mainBlue)" }} >
<img src={logo} alt="logo"/>
    </Link>
   
</li>





<li >

    <Link id="droptoggle" to='#' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
    Products <IoMdArrowDropdown />
    </Link>

    <div className="dropdown_menu">

    <Link id="droptoggle1" to='/Height_adjustable_desk' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
    Height Adjustable Desk
    </Link>

    {/* <div className="dropdown_sub_menu">
    <Link id="droptoggle" to='/shop' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
    Actiforce Desk
    </Link>

    <Link to='/product' activeStyle={{ color: "var(--mainBlue)" }}  className="nav-link">
    View all products
    </Link>
        
        </div> */}



     <Link to='/AustenTable' id="droptoggle2" activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
     Study Table
    </Link>
 {/* <div className="dropdown_sub_menu2">
   
    <Link  to='/AustenTable' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
     Austen Study table
    </Link>

    
        
        </div> */}
        
    </div>
   
</li>

{/* <li>

    <Link to='/blog' activeStyle={{ color: "var(--mainBlue)" }} className="nav-link">
Blog
    </Link>
   
</li> */}

<li>

    <Link to='/contact' activeStyle={{ color: "var(--mainBlue)" }}  className="nav-link">
Contact
    </Link>
   
</li>



             </LinkWrapper>
        );
    }
}

const LinkWrapper = styled.ul`

li{

    list-style:none;
    padding:10px 20px;
    margin:0px 10px;
   

}


#droptoggle, #droptoggle1,#droptoggle2 {

    svg{

        font-size:2rem;
    }
}



.dropdown_sub_menu{

 display:none;
 position:absolute;
 padding: 1.28rem !important;
 left: 98%;
    top: 9%;
 background-color:#111111;
 transition:0.5s;
 width:199px;
 text-align:left;
 transform:translateX(-2rem);
 transition:0.5s ease-in-out;
 background-color:#4e4e4e;
 clip-path: polygon(8% 18%, 8% 0, 100% 0, 100% 100%, 8% 100%, 8% 43%, 0 31%);
 z-index: 2;
    &:hover{
 display:block;


    }
}

.dropdown_sub_menu2{

display:none;
position:absolute;
left: 98%;
    top: 45.5%;
padding: 1rem;
background-color:#111111;
transition:0.5s;
width:200px;
text-align:left;
transform:translateX(-2rem);
transition:0.5s ease-in-out;
background-color:#4e4e4e;
clip-path: polygon(8% 31%, 8% 0, 100% 0, 100% 100%, 8% 100%, 8% 73%, 0 52%);

   &:hover{
display:block;

   }
}


 .dropdown_menu{
 display:none;
 position:absolute;
 top: 80%;
    padding: 1rem;
    background-color:#111111;
  transition:0.5s;
  width:260px;
  text-align:left;
  transform:translateX(-2rem);
  transition:0.5s ease-in-out;
  background-color:#191919;

    &:hover{
 display:block;

    }

} 


#droptoggle:hover ~ .dropdown_menu{
    display:block;


}

#droptoggle1:hover ~ .dropdown_sub_menu{
    display:block;


}
#droptoggle2:hover ~ .dropdown_sub_menu2{
    display:block;


}


.dropdown_sub_menu:hover ~ .dropdown_menu{

 display:block;
    
}



.dropdown_sub_menu2:hover ~ .dropdown_menu{

display:block;
   
}

@media (max-width:768px){

    li{

list-style:none;
padding:5px 20px;
margin:0px 10px;


}
.dropdown_sub_menu{
    
    top: 45%;
left: 10%;
padding: 0.1rem;
clip-path:polygon(45% 0%, 55% 10%, 100% 10%, 100% 100%, 0 99%, 0 10%, 36% 10%);
 

} 

#droptoggle2 {

svg{

    transform: rotate(90deg);
}
}

 #droptoggle1{

svg{

    transform: rotate(90deg);
}

}


.dropdown_sub_menu2{
    
    top: 90%;
    left: 10%;


    clip-path: polygon(45% 0%,55% 15%,100% 15%,100% 100%,0 99%,0 15%,36% 15%);; 

}
    .dropdown_menu{
    
        top: 345%;
    left: 15%;
    padding: 0.1rem;

}   

#logo{

    display:none;
}

}

.nav-link:hover{

    color:var(--mainBlue)
}

.nav-link{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Marvel', sans-serif;
    text-decoration:none;
    display:block;
    color:white;
    font-weight:bold;

}

@media (max-width:768px){
    height:${props => (props.open? '270px' : '0px')};
    margin-top:${props => (props.open? '0px' : '0px')};

overflow:hidden;
transition:0.3s ease-in-out;
background-color:#111111;


}

margin:0px auto;
padding:5px 10px;
align-items:center;


@media (min-width:768px){

display:flex;



}

`

export default NavbarLinks;