import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { FaHome } from 'react-icons/fa';
import {  MdMore, MdPermContactCalendar } from 'react-icons/md';

const mobnav = () => {
    return (
        <MobWrap>
      <div className="mgrid1">

      <Link to='/office' className="nav-link">
      <MdMore className="more" />
    </Link>
      </div>
      <div className="mgrid2">

      <Link to='/' className="nav-link">
      <FaHome className="home"/>
    </Link>
      </div>

      <div className="mgrid3">

      <Link to='/' className="nav-link">
      <MdPermContactCalendar className="contact"/>
    </Link>

      </div>

        </MobWrap>
    );
};

const MobWrap = styled.div`

@media (min-width:960px){

    display:none;
}
@media (max-width:960px){

display:block;
    color:grey;
    background-color:white;
    position:sticky;
    bottom:0;
    width:100%;
    height:50px;

    div{
width:33.33%;
float:left;
text-align:center;
font-size:22px;
        padding:20px;
        border:1px solid grey;
    }

    .nav-link{
        color:var(--mainBlue);
        padding:10px auto;
    }

}
  

`

export default mobnav;