import React, { Component } from 'react';
import styled from 'styled-components'
class NavbarIcon extends Component {
    render() {
        return (
            <TopBarWrap>

           <ul>
               <li>login</li>
               <li>example@gmail.com</li>
           </ul>
                
            </TopBarWrap>
        );
    }
}

const TopBarWrap = styled.div`
width:100%;
height:30px;
background:#111111;
border-bottom:1px solid rgba(255,255,255,0.1);
color:white;
padding:0px 0px 0px 0px;
ul{

  display:flex;
    padding:0px 30px;
    justify-content: flex-end;

}
ul > li{
    
text-decoration:none;
    padding:0px 15px;
    list-style:none;

}


`;

export default NavbarIcon;