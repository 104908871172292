/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navbar from '../components/global/Navbar/Navbar'
import { createGlobalStyle} from "styled-components"
import Mobnav from './global/mobnav/mobnav'
import NavbarTop from './global/Navbar/NavbarIcon';
import {Footer2} from './global/Footer/footer2';
import Footer from './global/Footer/footer';
import PageTransition from 'gatsby-plugin-page-transitions';

import "./bootstrap.min.css"
import './mousehover.css'
//import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
    <GlobalStyle/>
    <Navbar/>
    <PageTransition>
     { children }
     </PageTransition>
     <Footer/>
     <Footer2 />

    </>
  )
}

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Merriweather|Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Marvel&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{

  --mainBlue:#22B9BE;
  --mainRed:#E72F24;
  --mainYellow:#D8B727;
  --textColor:#5A5A5A;
  --mainWhite:#CDCDCE;

}

body {


  color:var(--textColor);
  font-family: 'Roboto', sans-serif;
}

#overlay{

background: rgba(10, 10, 10, 0.26) !important;

}

.styles_modal__gNwvD{
  max-width:100% !important;
  padding:10px;
}

@media (max-width:960px){

  .styles_modal__gNwvD{
  max-width:100% !important;
}


}


`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
