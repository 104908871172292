import React, { Component } from 'react';
import styled from 'styled-components';
import { FaAlignRight } from 'react-icons/fa';
import MenuIcon from '../../../images/menuicon.png'
import { Link } from 'gatsby';
import logo from '../../../images/logo.png'

class NavbarHeader extends Component {
    render() {

const {handleNavbar} = this.props;

        return (
            <HeaderWrapper>

              <Link  to='/' id="mlogo">
              <img src={logo} alt="logo" width="100%"/>
              </Link>  

              
              <img src={MenuIcon} width="30px" alt="navbarIcon"  className=" toggle-icon" onClick={()=>{

handleNavbar()

              }}/>

            </HeaderWrapper>
        );
    }
}

const HeaderWrapper = styled.div`

padding:0rem 1rem;
display:flex;
align-items:center;
justify-content:space-between;
.toggle-icon{

    font-size:1.75rem;
    color: var(--mainYellow);
    cursor:pointer;

}

#mlogo{

padding-top:10px;

}

@media (min-width:768px){

    .toggle-icon{

        display:none;
    }
    #mlogo{
        display:none;
 
    }
}

`

export default NavbarHeader;